<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                        <v-card-title class="title">
                            Receipt
                            <v-spacer></v-spacer>
                        </v-card-title>
                    <v-card outlined>
                        <v-card-title class="title pb-0">
                            <v-flex xs2>
                                <v-text-field :disabled="!sodEnable" placeholder="ST-76001" v-model="enrollCode"
                                              class="pa-0"
                                              label="Enroll Code" outlined dense></v-text-field>
                            </v-flex>
                            <v-flex xs5>
                                <v-autocomplete
                                        :disabled="!sodEnable"
                                        :search-input.sync="search"
                                        :items="students"
                                        @keyup="searchEnable=true"
                                        @change="searchEnable=false"
                                        hide-no-data
                                        :loading="studentLoading"
                                        class="pa-0"
                                        label="Search Students"
                                        v-model="form.name"
                                        :hint="!students.length?'Type atleast 3 characters':''" outlined dense
                                />
                            </v-flex>
                            <v-flex xs3>
                                <transition name="animate-css-transition"
                                            enter-active-class="animated fadeInRight"
                                            leave-active-class="animated fadeOutRight">
                                    <v-btn v-if="students.length || enrollCode.length"
                                           fab
                                           small
                                           @click="enrollCode='',students=[],form.name=''"
                                           style="z-index: 1; margin-left: 5px; margin-right: 5px"
                                           class="error">
                                        <v-icon dark>close</v-icon>
                                    </v-btn>
                                </transition>

                                <transition name="animate-css-transition"
                                            enter-active-class="animated fadeInRight"
                                            leave-active-class="animated fadeOutRight">
                                    <v-btn v-if="students.length || enrollCode.length"
                                           fab
                                           small
                                           @click="searchStudent"
                                           style="z-index: 1;"
                                           class="success">
                                        <v-icon dark>search</v-icon>
                                    </v-btn>
                                </transition>
                            </v-flex>


                            <v-flex xs3 sm3 v-if="studentData">
                                <v-select :items="paymentMethod" class="pa-0" label="Payment Method"
                                          v-model="payment_method" outlined dense/>
                            </v-flex>
                            <v-flex xs3 sm3 v-if="bankInfo && studentData">
                                <v-text-field v-model="cheque_no" class="pa-0" label="Cheque No"  outlined dense/>
                            </v-flex>
                            <v-flex xs3 sm3 v-if="bankInfo && studentData">
                                <v-text-field v-model="bank_name" class="pa-0" label="Bank Name" outlined dense/>
                            </v-flex>
                            <v-flex xs12 sm12 v-if="remainingAdvance > 0">
                                <div style="color: #ff5252">
                                    Remaining Advance Amount: {{remainingAdvance.currency()}}
                                </div>
                            </v-flex>
                        </v-card-title>

                    </v-card>

                    <!--<v-card>-->
                    <!--<v-alert :value="true" color="info" v-if="studentData">-->
                    <!--<table class="v-datatable v-table theme&#45;&#45;light" width="100%">-->
                    <!--<tbody>-->
                    <!--<tr>-->
                    <!--<th>Name:</th>-->
                    <!--<td>{{name}}</td>-->
                    <!--<th>Roll:</th>-->
                    <!--<td>{{roll}}</td>-->
                    <!--<th>Grade:</th>-->
                    <!--<td>{{grade}}</td>-->
                    <!--<th>Section:</th>-->
                    <!--<td>"{{section}}"</td>-->
                    <!--</tr>-->
                    <!--</tbody>-->
                    <!--</table>-->
                    <!--</v-alert>-->
                    <!--</v-card>-->
                    <v-container>
                        <strong v-if="!sodEnable" style="color:#ff5252;font-size: 16px;">
                            <v-icon color="#ff5252">warning</v-icon>
                            {{sod_date}}</strong>
                        <strong v-if="sodEnable">
                            <v-icon small>date_range</v-icon>
                            Transaction Date : {{sod_date}}</strong>
                    </v-container>
                    <v-data-table :headers="headers" hide-default-footer :items="items.data"
                                  :loading="form.loading">
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td class="text-xs-left">{{ index +1 }}</td>
                                <td class="text-xs-left">{{ item.fee_head }}
                                    <div>
                                        <small><strong>({{item.total.currency()}})</strong></small>
                                    </div>
                                </td>
                                <td class="text-xs-left">
                                    <span v-for="(x,i) in fullmonths(item.bill_month)" :key="i">
                                        {{x.text}}
                                    </span>
                                </td>
                                <!--<td class="text-xs-left">{{item.fee_head_amount }}</td>-->
                                <td class="text-xs-left">{{item.due_amount }}</td>
                                <td class="text-xs-left">
                                    <input autocomplete="off" @keyup="checkVal(index)" class="boxField"
                                        v-model="paymentDetail[index].amount"/>

                                    <!--<v-text-field @keyup="checkVal(index)"-->
                                    <!--v-model="paymentDetail[index].amount">-->
                                    <!--</v-text-field>-->


                                    <span v-if="paymentDetail[index].discount > 0 " class="amountPayable">Payable Amount: {{item.due_amount - paymentDetail[index].discount}}</span>
                                </td>

                                <td class="text-xs-left">
                                    <input autocomplete="off" @keyup="discountVal(index)" class="boxField"
                                        v-model="paymentDetail[index].discount"/>

                                    <!--<v-text-field @keyup="discountVal(index)"-->
                                    <!--v-model="paymentDetail[index].discount"></v-text-field>-->

                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                    <v-alert :value="true" color="info" v-if="studentData && totalSum > 0">
                        Total Due amount: {{totalSum.currency()}} /-
                        ({{convertNumberToWords(totalSum, true)}})
                    </v-alert>
                </v-card>
                <v-card-title class="title" v-if="datavalue && studentData">
                    <v-flex xs12 sm6>
                        <v-textarea rows="2"
                                    v-model="remarks"
                                    label="Remarks"
                                    hint="Comment any other information"
                        ></v-textarea>
                    </v-flex>
                    <v-flex xs3 sm3>
                        <transition name="animate-css-transition" enter-active-class="animated fadeInRight"
                                    leave-active-class="animated fadeOutRight">
                            <v-text-field label="Advance" type="number" v-model="adv" outlined disabled
                            ></v-text-field>
                        </transition>
                    </v-flex>
                    <v-flex xs3 sm3 v-if="studentData">
                        <transition name="animate-css-transition" enter-active-class="animated fadeInRight"
                                    leave-active-class="animated fadeOutRight">
                            <v-text-field label="Payment Amount" type="number"
                                          v-model="amountValue"
                                          outlined dense
                            ></v-text-field>
                        </transition>
                    </v-flex>
                </v-card-title>
                <v-card-title v-if="datavalue" style="margin-top: -60px;">
                    <v-flex xs12 sm12 v-if="studentData" class="text-xs-right">
                        <transition name="animate-css-transition" enter-active-class="animated fadeInRight"
                                    leave-active-class="animated fadeOutRight">
                            <v-btn v-if="$auth.can('receipt-create')" outlined color="success"
                                   @click="save" ma-0
                                   :disabled="payment_amount <= 0">Submit
                            </v-btn>
                        </transition>
                    </v-flex>
                </v-card-title>
            </v-flex>
        </v-layout>
        <v-dialog v-model="confirmDialog" persistent max-width="460px">
            <v-card>
                <v-card-title class="title pa-3 warning white--text">
                    <v-icon class="mr-2 white--text">warning</v-icon>
                    Please, wait!
                </v-card-title>
                <v-card-text class="ma-0">
                    <v-chip>{{parseFloat(payment_amount).currency()}}</v-chip>
                    <v-chip color="green" text-color="white">{{convertNumberToWords(payment_amount, true)}}</v-chip>
                    <br>
                    Are you sure you want to pay this amount?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" outlined small @click="confirmDialog = false">Cancel</v-btn>
                    <v-btn color="warning" outlined small @click="submit">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="alertDialog" persistent max-width="460px">
            <v-card>
                <v-card-title class="title pa-3 error white--text">
                    <v-icon class="mr-2 white--text">warning</v-icon>
                    Please, wait!
                </v-card-title>
                <v-card-text class="ma-0">
                    Sod date is not set for today's Transaction . Are you sure want to continue with this <span
                        style="color: red"> {{sod_date}} </span>date?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="warning" outlined small @click="alertDialog = false">Cancel</v-btn>
                    <v-btn color="success" outlined small @click="alertDialog = false">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'

    import {convertNumberToWords, formatNumber} from '@/library/helpers'
    // import {formatNumber} from '@/library/helpers'
    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();
    import Form from '@/library/Form'

    export default {
        data: () => ({
            form: new Form({
                bill_month: '',
                grade_id: '',
                enroll: '',
                name: ''

            }, '/api/invoice'),

            search: null,
            headers: [
                {text: 'S. No', align: 'left', value: 'id', sortable: false},
                {text: 'Fee Heads', align: 'left', value: 'fee_head', sortable: false},
                {text: 'Months', align: 'left', value: 'month', sortable: false},
                // {text: 'Fee Head Amount', align: 'left', value: 'section', sortable: false},
                {text: 'Due Amount', align: 'left', value: 'bill_date', sortable: false},
                {text: 'Payment', align: 'left', value: 'payment', sortable: false},
                {text: 'Discount', align: 'left', value: 'discount', sortable: false},
            ],
            paymentMethod: [
                {text: 'Cash', value: 'cash'},
                {text: 'Cheque', value: 'cheque'},

            ],
            grades: [],
            today: nd.format('YYYY-MM-DD'),


            studentData: false,
            studentLoading: false,
            payment_amount: 0,
            payment_method: 'cash',
            enrollCode: '',
            // enrollCode:'',
            enroll_id: '',
            months: [],
            month: '',
            generate: false,
            print: false,
            billingSetting: {},
            confirmDialog: false,
            items: [],
            name: '',
            input: false,
            roll: null,
            grade: null,
            section: null,
            fee_heads: [],
            datavalue: false,
            bankInfo: false,
            remarks: '',
            bank_name: '',
            cheque_no: '',
            sod_date: '',
            sodEnable: false,
            paymentDetail: [
                {ids: '', amount: 0, 'discount': 0},
            ],
            adv: '',
            totalSum: 0,
            students: [],
            alertDialog: false,
            monthly: [
                {text: 'Baisakh', value: '01'},
                {text: 'Jestha', value: '02'},
                {text: 'Ashadh', value: '03'},
                {text: 'Shrawan', value: '04'},
                {text: 'Bhadra', value: '05'},
                {text: 'Ashwin', value: '06'},
                {text: 'Kartik', value: '07'},
                {text: 'Mangshir', value: '08'},
                {text: 'Poush', value: '09'},
                {text: 'Margh', value: '10'},
                {text: 'Falgun', value: '11'},
                {text: 'Chaitra', value: '12'},
            ],
            quarterly: [
                {text: 'Baisakh, Jestha, Ashadh', value: '01,02,03',},
                {text: 'Shrawan, Bhadra, Ashwin', value: '04,05,06'},
                {text: 'Kartik, Mangshir, Poush', value: '07,08,09'},
                {text: 'Margh, Falgun, Chaitra', value: '10,11,12'},
            ],
            searchEnable: true,
            remainingAdvance: 0,

        }),

        computed: {
            ...mapState(['batch']),

            amountValue: {
                get: function () {
                    return this.payment_amount;
                },
                set: function (value) {
                    let i = 0;
                    if (this.totalSum > value) {
                        for (i = 0; i < this.items.data.length; i++) {
                            this.paymentDetail[i].amount = 0;
                        }
                    } else {
                        for (i = 0; i < this.items.data.length; i++) {
                            this.paymentDetail[i].amount = this.items.data[i].due_amount
                        }
                        this.adv = value - this.payment_amount;
                    }
                    this.payment_amount = value;
                    return value;
                },
            }
        },

        mounted() {
            this.currentSod();
            // this.students = [{
            //     value: 'ST-76024',
            //     text: 'ram(8-B) -4'
            // },
            //     {
            //         value: 'ST-76023',
            //         text: 'hari(8-B) -4'
            //     }];
        },

        watch: {
            'batch': function (value) {
            },
            'payment_amount': function (value) {
                this.paidAmount(value);
            },
            'enrollCode': function (value) {
                this.studentData = false;
                this.items.data = [];
                this.form.name = '';

                if (value && value.length > 7) {
                    this.searchStudent();
                }
            },
            'payment_method': function (value) {
                if (this.payment_method === 'cheque') this.bankInfo = true;
                else this.bankInfo = false;
            },
            search(val) {
                if (!this.searchEnable) return false;
                if (!val) {
                    this.students = [];
                    this.studentLoading = false;
                    return;
                }
                if (this.isLoading) return;
                if (this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
                this.studentLoading = true;
                this.timer = setTimeout(() => {
                    this.$rest.get('api/search-student-grade-section?search=' + val)
                        .then(({data}) => {
                            this.students = data.data.map(item => {
                                return {
                                    value: item.enroll_code,
                                    text: item.name + ' (' + item.grade + '-' + item.section + ') - ' + item.roll,
                                    enrollCode: item.enroll_code
                                }
                            });
                            // console.log(data);
                            // this.remainingAdvance = data.data.advance
                        }).catch(err => {
                    }).finally(() => (this.studentLoading = false))
                }, 500);

            },
            'form.name': function (value) {
                this.students.filter(res => {
                    if (res.value == value) {
                        this.enrollCode = res.enrollCode;
                    }
                })
            }
        },

        methods: {
            convertNumberToWords,
            checkVal(index) {
                let totalPaymentAmount = 0;
                let totalDiscountAmount = 0;
                this.adv = 0;
                if (this.datavalue) {
                    if (this.paymentDetail[index].amount > this.items.data[index].due_amount) {
                        this.paymentDetail[index].amount = this.items.data[index].due_amount;
                    }
                    this.paymentDetail.map(function (data) {
                        if ([null, ''].includes(data.amount)) data.amount = '';
                        if (data.amount != '') totalPaymentAmount += parseFloat(data.amount);
                        totalDiscountAmount += parseFloat(data.discount);
                    });
                }
                this.payment_amount = totalPaymentAmount - totalDiscountAmount;

            },
            discountVal(index) {
                let totalPaymentAmount = 0;
                let totalDiscountAmount = 0;
                this.paymentDetail.map(function (data) {
                    if ([null, ''].includes(data.amount)) data.amount = '';
                    if (data.amount != '') totalPaymentAmount += parseFloat(data.amount);

                    if ([null, ''].includes(data.discount)) data.discount = '';
                    if (data.discount != '') totalDiscountAmount += parseFloat(data.discount);
                });

                if (this.paymentDetail[index].discount > this.items.data[index].due_amount) {
                    this.paymentDetail[index].discount = this.items.data[index].due_amount;
                    totalDiscountAmount = this.items.data[index].due_amount;
                }
                this.payment_amount = totalPaymentAmount - totalDiscountAmount;

            },
            fullmonths(data) {
                let monthlydata = data.split(',');

                let monthsColl = this.monthly.filter(item => {
                    if (monthlydata.includes(item.value)) {
                        return item.text;
                    }
                });

                return monthsColl;
            },
            paidAmount(amount) {
                let totalPaymentAmount = 0;
                let totalDiscountAmount = 0;
                this.paymentDetail.map(function (data) {
                    if ([null, ''].includes(data.amount)) data.amount = '';
                    if (data.amount != '') totalPaymentAmount += parseFloat(data.amount);

                    if ([null, ''].includes(data.discount)) data.discount = '';
                    if (data.discount != '') totalDiscountAmount += parseFloat(data.discount);

                });
                if (this.payment_amount > (totalPaymentAmount - totalDiscountAmount)) {
                    if (this.datavalue) {
                        let i = 0;
                        for (i = 0; i < this.items.data.length; i++) {
                            this.paymentDetail[i].ids = this.items.data[i].ids
                        }
                        let checkedAmount = parseFloat(amount) + totalDiscountAmount;
                        if (this.totalSum > checkedAmount) {
                            this.adv = 0;
                            for (i = 0; i < this.items.data.length; i++) {
                                if (amount >= this.items.data[i].due_amount) {
                                    this.paymentDetail[i].amount = this.items.data[i].due_amount;
                                    amount = amount - this.paymentDetail[i].amount + parseFloat(this.paymentDetail[i].discount);
                                } else {
                                    if (amount <= this.items.data[i].due_amount) {
                                        this.paymentDetail[i].amount = parseFloat(amount) + parseFloat(this.paymentDetail[i].discount) > 0 ? parseFloat(amount) + parseFloat(this.paymentDetail[i].discount) : 0;
                                        amount = amount - this.paymentDetail[i].amount + parseFloat(this.paymentDetail[i].discount);
                                    }
                                }
                            }
                        } else {
                            for (i = 0; i < this.items.data.length; i++) {
                                this.paymentDetail[i].amount = this.items.data[i].due_amount
                            }
                            this.payment_amount = amount;
                            this.adv = checkedAmount - this.totalSum;
                        }
                    }
                } else {
                    this.adv = 0;
                }
            },
            searchStudent() {
                this.$rest.get('/api/fee-payment/' + this.enrollCode).then(({data}) => {
                    this.studentData = true;
                    this.input = true;
                    this.name = data.student.name;
                    this.roll = data.student.roll;
                    this.section = data.student.section;
                    this.grade = data.student.grade;
                    this.students = [{
                        value: this.enrollCode,
                        enrollCode: this.enrollCode,
                        text: this.name + ' (' + this.grade + '-' + this.section + ') - ' + this.roll
                    }];
                    this.remainingAdvance = data.advance


                    this.form.name = this.enrollCode;
                    if (data.totalSum) {
                        this.totalSum = data.totalSum;
                    } else {
                        this.totalSum = 0;
                    }
                    this.enroll_id = data.student.enroll_id;
                    this.payment_amount = data.totalSum;
                    if (data.data.length > 0) {
                        this.input = false;
                        this.datavalue = true;
                        let i = 0;
                        this.paymentDetail = [];
                        for (i = 0; i < data.data.length; i++) {
                            this.paymentDetail.push({
                                ids: data.data[i].ids,
                                amount: data.data[i].due_amount,
                                discount: 0
                            });
                        }
                        this.items.data = data.data;
                    } else {
                        this.input = true;
                        this.datavalue = false;
                    }
                }).catch(e => {
                    this.$events.fire('notification', {message: 'No record found.', status: 'error'});
                })
            },
            save() {
                if (this.payment_method === 'cheque' && (!this.cheque_no || !this.bank_name)) {
                    this.$events.fire('notification', {
                        message: 'Please fill the  Bank/Cheuqe information correctly.',
                        status: 'error'
                    })
                } else {
                    this.confirmDialog = true;
                }
            },
            submit() {
                let formData = {};
                formData.fees = JSON.stringify(this.paymentDetail);
                formData.amount = this.payment_amount;
                formData.enroll_id = this.enroll_id;
                formData.transaction_type = 'fee';
                formData.remarks = this.remarks;
                formData.via = this.payment_method;
                formData.bank_name = this.bank_name;
                formData.cheque_no = this.cheque_no;
                formData.advance = this.adv;
                formData.sod_date = this.sod_date;

                this.$rest.post('/api/fee-payment', formData).then(({data}) => {
                    this.paymentDetail = [];
                    this.items.data = [];
                    this.studentData = false;
                    this.students = [];
                    this.enrollCode = '';
                    this.amount = '';
                    this.remarks = '';
                    this.adv = '';
                    this.cheque_no = '',
                        this.bank_name = '',
                        this.confirmDialog = false;
                    window.open(data.data.url)

                    this.$events.fire('notification', {message: data.message, status: 'success'});
                })
            },
            // formatNumber(num) {
            //     return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            // },
            getBankInfo() {
            },
            currentSod() {
                this.$rest.get('/api/sod-current').then(({data}) => {
                    if (data.sod) {
                        this.sodEnable = true;
                        this.sod_date = data.sod;
                        if (this.today != this.sod_date) this.alertDialog = true;
                    } else {
                        this.sodEnable = false;
                        this.sod_date = 'Start of day has not been set. Please contact your admin.'
                    }
                });

            }
        }
    }
</script>
<style lang="scss" scoped>
    table.v-datatable.v-table.theme--light {
        width: 100%;
    }

    .amountPayable {
        position: absolute;
        margin-top: 23px;
        font-size: 10px;
        font-weight: 600;
        margin-left: -80px;
    }

    .boxField {
        border: 1px solid #ccc;
        font-weight: bold;
        border-radius: 3px;
        width: 80px;
        height: 25px !important;
        text-align: center !important;
    }
</style>